const constants = {}

constants.roles = [
    { value: 'club-coach', label: 'Coach' },
    { value: 'club-admin', label: 'Gestionnaire de club' }
];

constants.civilities = [
    { value: 'mister', label: 'Monsieur' },
    { value: 'miss', label: 'Madame' }
];

constants.status = [
    { value: 'active', label: 'Actif' },
    { value: 'inactive', label: 'Inactif' },
    { value: 'archived', label: 'Archivé' },
    { value: 'progress', label: 'En cours' },
    { value: 'archived', label: 'Archivé' },
    { value: 'waiting-validation', label: 'En attente de validation' },
    { value: 'change-needed', label: 'En attente de changement' },
    { value: 'waiting-payment', label: 'En attente du paiement' },
    { value: 'refused', label: 'Refusée' },
    { value: 'accepted', label: 'Acceptée' },
    { value: 'dropped', label: 'Abandonné' },
];

constants.fields = [
    { value: 'date', label: 'Date' },
    { value: 'text', label: 'Texte' },
    { value: 'number', label: 'Nombre' },
    { value: 'select', label: 'Liste déroulante' }
];

constants.discountTypes = [
    { value: 'amount', label: 'Montant' },
    { value: 'percentage', label: 'Pourcentage' }
];

constants.frequencies = [
    { value: 'days', label: 'jours' },
    { value: 'weeks', label: 'semaines' },
    { value: 'months', label: 'mois' }
];

constants.weekDays = [
    { value: 'monday', label: 'Lundi' },
    { value: 'tuesday', label: 'Mardi' },
    { value: 'wednesday', label: 'Mercredi' },
    { value: 'thursday', label: 'Jeudi' },
    { value: 'friday', label: 'Vendredi' },
    { value: 'saturday', label: 'Samedi' },
    { value: 'sunday', label: 'Dimanche' },
];

constants.all = constants.roles.concat(constants.status);
constants.all = constants.all.concat(constants.civilities);
constants.all = constants.all.concat(constants.weekDays);
constants.all = constants.all.concat(constants.frequencies);
constants.all = constants.all.concat(constants.fields);
constants.all = constants.all.concat(constants.discountTypes);

export default constants;