import React, {useState} from 'react';
import constants from "../assets/utils/constants";

const CustomTag = ({value, className}) => {

    const [matchedConstant, setMatchedConstant] = useState(null);

    React.useEffect(() => {
        setMatchedConstant(constants.all.find((e) => { return e.value === value }));
    }, [value]);

    return matchedConstant ? (
        <span className={'custom-tag ' + matchedConstant.value + (className ? className : '')}>{matchedConstant.label}</span>
    ) : <span className={'custom-tag ' + (className ? className : '')}>{value ? value : 'Non renseigné(e)'}</span>
}

export default CustomTag;