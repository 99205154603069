import React, {useContext} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import logo from '../assets/images/fhcm.svg';
import AuthContext from "../contexts/AuthContext";
import '../assets/styles/AdminPanel.css';
import '../assets/styles/Public.css';
import {CircularProgress, Tooltip} from "@mui/material";
import SubscriptionForm from "./SubscriptionForm";
import SubscriptionStatus from "./SubscriptionStatus";
import ValidationList from "./ValidationList";

const AdminPanel = ({ appRefresh, setAppRefresh }) => {

    const { company, setToken, setCompany } = useContext(AuthContext);

    const handleDisconnect = () => {
        localStorage.clear();
        setToken(null);
        setCompany(null);
    }

    return company ? (
        <div className='main-app'>
            <div className='main-app-right-part'>
                <div className='main-navbar-container'>
                    <div className='main-navbar'>
                        <div className='img-container'>
                            <img alt='logo' src={logo} width={200}/>
                        </div>
                        <div className='user-info-container'>
                            <Tooltip leaveDelay={800} placement="bottom-end" id='options-tooltip' title={
                                <React.Fragment>
                                    <div className='tooltip-container'>
                                        <span className='logout' onClick={handleDisconnect}>Déconnexion</span>
                                    </div>
                                </React.Fragment>
                            }>
                                <div className='user-info-block'>
                                    <div className='user-data-container'>
                                        <span className='user-title'>{company.companyName}</span>
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className='main-router-content'>
                    <Routes>
                        <Route path='/' element={<SubscriptionStatus />} />
                        <Route path='admin' element={<ValidationList />} />
                        <Route path='subscription' element={<SubscriptionForm appRefresh={appRefresh} setAppRefresh={setAppRefresh} />} />
                        <Route path='*' element={<Navigate to={'/my-account'} />} />
                    </Routes>
                </div>
            </div>
        </div>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default AdminPanel;