import React, {useContext, useState} from 'react';
import axios from 'axios';
import "../assets/styles/Login.css";
import AuthContext from "../contexts/AuthContext";
import {Navigate, useLocation} from "react-router-dom";
import logo from '../assets/images/fhcm.svg';
import {CircularProgress, Grid} from "@mui/material";
import dayjs from "dayjs";

const Login = () => {

    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const {state} = useLocation();
    const {company, setToken} = useContext(AuthContext);

    if (redirectToReferrer === true || company) {
        return <Navigate to={ state?.from || '/my-account'} />;
    }

    const handleSubmit = (event) => {
        if(event.type === 'click' || event.key === 'Enter') {
            setError(null);
            setLoader(true);
            axios.post(process.env.REACT_APP_PUBLIC_API_URL + "login", { code: code })
                .then((response) => {
                    localStorage.setItem('session_token', response.data.token);
                    setToken(response.data.token);
                    setRedirectToReferrer(true);
                })
                .catch((error) => {
                    console.log(error);
                    setError(error.response.data.message);
                    setLoader(false);
                });
        }
    }

    return (
        <div className='public-panel-container'>
            <div className='public-router-content'>
                <div className="main-login" onKeyDown={handleSubmit}>
                    <div className='login-container'>
                        <div className='login-right-form'>
                            <div className='form-container'>
                                <img src={logo} width={300} alt='logo' />
                                <h1>Bienvenue !</h1>
                                <h2>Saisissez le code de votre maison ci-dessous</h2>
                                <span className={'login-message ' + (error ? 'error' : 'hidden')}>{error}</span>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <input className='input' type='text' id='companyCode' placeholder='Votre code' onChange={e => setCode(e.target.value)} />
                                    </Grid>
                                </Grid>
                                <div className="forgot-password" />
                                <button className='main-button blue-button' disabled={loader} onClick={handleSubmit}>{!loader ? 'Connexion' : <CircularProgress size={20} />}</button>
                            </div>
                            <div className='rights-container'>
                                <span>{dayjs().format('YYYY')} Fédération de la Haute Couture et de la Mode</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
