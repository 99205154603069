import {useContext} from "react";
import AuthContext from "../contexts/AuthContext";
import {Navigate} from "react-router-dom";
import {Container} from "@mui/material";
import valid_logo from "../assets/images/validation.png";

const SubscriptionStatus = () => {

    const { company } = useContext(AuthContext);

    return company.signedOn ? (
        <div className='subscription-container'>
            <Container>
                <div className='subscription-instructions'>
                    <img src={valid_logo} alt='valid_logo' width={150}/>
                    <h1>Adhésion 2024</h1>
                    <h2>Votre demande d'adhésion a bien été prise en compte !</h2>
                    <span>Elle est en cours de validation par la fédération.</span>
                </div>
            </Container>
        </div>
    ) : <Navigate to={'/my-account/subscription'} />;
}

export default SubscriptionStatus;