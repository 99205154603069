import {Navigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../contexts/AuthContext";
import CustomList from "./CustomList";
import CustomTag from "./CustomTag";
import dayjs from "dayjs";
import axios from "axios";
import * as XLSX from 'xlsx';
import {CircularProgress} from "@mui/material";

const ValidationList = () => {

    const [companies, setCompanies] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { company, token} = useContext(AuthContext);

    useEffect(() => {
        if(company.isAdmin) {
            setIsLoading(true);
            axios.get(process.env.REACT_APP_PUBLIC_API_URL + "companies", {
                headers: { "Authorization": "Bearer " + token }
            })
                .then(response => {
                    setIsLoading(false);
                    setCompanies(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [token]);

    const columns = [
        {
            Header: 'Code',
            accessor: 'companyCode',
            icon: 'tag',
        },
        {
            Header: 'Maison',
            accessor: 'companyName',
            icon: 'user',
            Cell: ({cell: {value}}) => <CustomTag value={value} />
        },
        {
            Header: 'Statut',
            accessor: 'signedOn',
            icon: 'status',
            Cell: ({cell: {value}}) => <CustomTag className={value ? 'accepted' : 'refused'} value={value ? `Rempli le : ${dayjs(value).format('DD MMMM YYYY à HH:mm')}` : 'Non rempli'}/>
        },
        {
            Header: 'Dernière connexion',
            accessor: 'lastLogin',
            icon: 'date',
            Cell: ({cell: {value}}) => <CustomTag className={value ? '' : 'refused'} value={value ? dayjs(value).format('DD MMMM YYYY à HH:mm') : 'Aucune connexion'}/>
        },
        {
            Header: 'Bulletin',
            icon: 'file',
            Cell: (props) => props.row.original.signedOn ? (
                <a href={`${process.env.REACT_APP_FILES_API_URL}${props.row.original.id}.pdf`} target='_blank'><button className='main-button view'>Voir</button></a>
            ) : (
                <span>Aucun fichier</span>
            )
        }
    ]

    const handleDownload = () => {

        let data = companies.map(company => [
            company.id,
            company.companyCode,
            company.companyName,
            company.companyFullName,
            company.tradeRegisterNb,
            company.vatNb,
            company.officeAddress,
            company.postalCode,
            company.city,
            company.internationalRegistrationNb,
            company.hasLocalStudio,
            company.executiveFirstName,
            company.executiveLastName,
            company.executiveRole,
            company.executiveEmail,
            company.legalFirstName,
            company.legalLastName,
            company.legalEmail,
            company.financialFirstName,
            company.financialLastName,
            company.financialAddress,
            company.financialEmail,
            company.turnover,
            company.doneIn,
            company.signedOn,
            company.lastLogin,
            company.signedOn ? process.env.REACT_APP_FILES_API_URL + company.id + '.pdf' : ''
        ].flat());

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([[
            'Identifiant',
            'Code maison',
            'Nom maison',
            'Raison sociale',
            'N° SIRET',
            'N° TVA',
            'Adresse siège social',
            'Code postal',
            'Ville',
            'N° SIRET si international',
            'Locaux en france',
            'Prénom représentant',
            'Nom représentant',
            'Agissant en qualité de',
            'Email représentant',
            'Prénom contact administratif',
            'Nom contact administratif',
            'Email contact administratif',
            'Prénom contact financier',
            'Nom contact financier',
            'Adresse facturation contact financier',
            'Email contact financier',
            'Chiffre d\'affaires',
            'Fait à',
            'Date de signature',
            'Dernière connexion',
            'Lien bulletin'
        ].flat(), ...data]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Données');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelData = new Uint8Array(excelBuffer);
        const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);
        link.download = 'export.xlsx';
        link.click();
    };

    return company.isAdmin ? (
        <div className='validation-container'>
            { !isLoading ? (
                <>
                    <button className='main-button download margin-bottom' onClick={handleDownload}>Télécharger la base de données</button>
                    <CustomList
                        entity={companies}
                        columns={columns}
                        isSelectable={false}
                    />
                </>
            ) : <CircularProgress size={30} /> }
        </div>
    ) : <Navigate to={'/my-account'} />;
}

export default ValidationList;