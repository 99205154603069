import {Routes, Route} from "react-router-dom";
import AuthContext from "./contexts/AuthContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import './assets/styles/App.css';
import axios from "axios";
import AdminPanel from "./components/AdminPanel";
import AdminRoute from "./components/AdminRoute";
import locale_fr from 'dayjs/locale/fr';
import dayjs from "dayjs";
import {CircularProgress} from "@mui/material";
import Login from "./components/Login";

const App = () => {

    dayjs.locale(locale_fr);

    const [token, setToken] = useState(localStorage.getItem('session_token'));
    const [company, setCompany] = useState(null);
    const [appRefresh, setAppRefresh] = useState(0);
    const [userLoaded, setUserLoaded] = useState(false);

    const contextValue = {
        token: token,
        company: company,
        setCompany:setCompany,
        setToken: setToken
    }

    React.useEffect( () => {
        setUserLoaded(false);
        if(localStorage.getItem('session_token')) {
            axios.get(process.env.REACT_APP_DATA_API_URL + 'companies/me', { headers:{ "Authorization": "Bearer " + localStorage.getItem('session_token') }}).then((response) => {
                setCompany(response.data);
                setUserLoaded(true);
            }).catch(() => {
                setUserLoaded(true);
            });
        } else {
            setUserLoaded(true);
        }
    }, [token, appRefresh]);

    return userLoaded ? (
        <AuthContext.Provider value={contextValue}>
            <Routes>
                <Route path='my-account/*' element={
                    <AdminRoute>
                        <AdminPanel appRefresh={appRefresh} setAppRefresh={setAppRefresh} />
                    </AdminRoute>
                } />
                <Route path="*" element={<Login />} />
            </Routes>
        </AuthContext.Provider>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default App;