import React from "react";

const AuthContext = React.createContext({
    token:null,
    company: null,
    setCompany: () => {},
    setToken: () => {},
});

export default AuthContext;
