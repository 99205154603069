import {
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
    Select, Radio, RadioGroup, Chip, CircularProgress
} from "@mui/material";
import Switch from "@mui/material/Switch";
import {useContext, useState} from "react";
import SignatureCanvas from 'react-signature-canvas';
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
import {Navigate, useNavigate} from "react-router-dom";
import dayjs from "dayjs";

const SubscriptionForm = () => {

    const [signatureTooSmall, setSignatureTooSmall] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [error, setError] = useState();
    const [lang, setLang] = useState('fr');
    const [subscription, setSubscription] = useState({});
    const { company, setCompany, token } = useContext(AuthContext);
    const navigate = useNavigate();
    let sigPad = {};

    if(company.isAdmin) {
        return <Navigate to='/my-account/admin' />
    }

    const resetPad = () => {
        sigPad.clear()
        setSignatureTooSmall(false)
    }

    const handleSubmit = () => {
        if(validateSignature()) {
            setError(null);
            setButtonLoading(true);
            axios.post(process.env.REACT_APP_PUBLIC_API_URL + "companies/subscribe",{...subscription, lang: lang, signature : sigPad.toDataURL() }, {
                headers: { "Authorization": "Bearer " + token }
            })
                .then(() => {
                    setButtonLoading(false);
                    setCompany({...company, signedOn: dayjs() });
                    navigate('/my-account');
                })
                .catch((error) => {
                    console.log(error);
                    setError(error.response.data);
                    setButtonLoading(false);
                });
        }
    }

    const validateSignature = () => {
        let signatureSize = 0;
        const signaturePoints = sigPad.toData();
        if(signaturePoints.length > 0) {
            signaturePoints.forEach(array => signatureSize += array.length);
        }
        signatureSize < 40 ? setSignatureTooSmall(true) : setSignatureTooSmall(false);
        return signatureSize >= 40;
    }

    return company.signedOn ? <Navigate to='/my-account' /> : (
        <div className='subscription-container'>
            <Container>
                <div className='subscription-instructions'>
                    <h1>Bulletin d'adhésion</h1>
                    <h2>Merci de remplir le formulaire ci-dessous afin de procéder à votre inscription</h2>
                    <div className='lang-switcher'>
                        <span>Français</span>
                        <Switch checked={lang === 'en'} onChange={e => setLang(e.target.checked ? 'en' : 'fr')} />
                        <span>English</span>
                    </div>
                </div>
                <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {lang === 'en' ? 'Company Information' : 'Informations sur l\'entreprise' }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('companyFullName')}
                            onChange={e => setSubscription({...subscription, companyFullName: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'Company Name' : 'Raison sociale' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('tradeRegisterNb')}
                            onChange={e => setSubscription({...subscription, tradeRegisterNb: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'Trade Register Number' : 'N° SIRET' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('vatNb')}
                            onChange={e => setSubscription({...subscription, vatNb: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'VAT number' : 'N° TVA' }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('officeAddress')}
                            onChange={e => setSubscription({...subscription, officeAddress: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'Registered Office Address' : 'Adresse siège social' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('postalCode')}
                            onChange={e => setSubscription({...subscription, postalCode: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'Postal code' : 'Code postal' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('city')}
                            onChange={e => setSubscription({...subscription, city: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'City' : 'Ville' }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className='divider' />
                    </Grid>
                    <Grid item xs={12}>
                        {lang === 'en' ? (
                            <span>If your registered office is located abroad, please complete the information below: Is your company also on the official register of companies in France? If yes, please indicate your French trade registration number:</span>
                        ) : (
                            <span>Si votre siège social est situé à l’étranger veuillez compléter les informations ci-dessous : Votre société est-elle également immatriculée au registre du commerce en France ? Si oui, merci d’indiquer votre numéro de SIRET :</span>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            onChange={e => setSubscription({...subscription, internationalRegistrationNb: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'French Trade Registration Number' : 'N° SIRET' }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {lang === 'en' ? (
                            <span>Are the company’s design studios AND at least one prototyping workshop located in France ?</span>
                        ) : (
                            <span>Est-ce que les studios de création de la société ET au moins un atelier d’élaboration des modèles sont situés en France ? </span>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                            <FormControlLabel value="yes" control={<Radio onChange={e => setSubscription({...subscription, hasLocalStudio: e.target.checked })} />} label={lang === 'en' ? 'Yes' : 'Oui' } />
                            <FormControlLabel value="no" control={<Radio onChange={e => setSubscription({...subscription, hasLocalStudio: !e.target.checked })} />} label={lang === 'en' ? 'No' : 'Non' } />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <div className='divider' />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {lang === 'en' ? 'Represented by' : 'Représenté par' }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('executiveFirstName')}
                            onChange={e => setSubscription({...subscription, executiveFirstName: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'First name' : 'Prénom' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('executiveLastName')}
                            onChange={e => setSubscription({...subscription, executiveLastName: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'Last name' : 'Nom' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('executiveRole')}
                            onChange={e => setSubscription({...subscription, executiveRole: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'Acting in the capacity of' : 'Agissant en qualité de' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('executiveEmail')}
                            onChange={e => setSubscription({...subscription, executiveEmail: e.target.value})}
                            fullWidth
                            label="Email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className='divider' />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {lang === 'en' ? 'Administrative and legal contact' : 'Contact administratif et juridique' }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('legalFirstName')}
                            onChange={e => setSubscription({...subscription, legalFirstName: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'First name' : 'Prénom' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('legalLastName')}
                            onChange={e => setSubscription({...subscription, legalLastName: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'Last name' : 'Nom' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('legalEmail')}
                            onChange={e => setSubscription({...subscription, legalEmail: e.target.value})}
                            fullWidth
                            label="Email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className='divider' />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {lang === 'en' ? 'Financial contact' : 'Contact financier' }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('financialFirstName')}
                            onChange={e => setSubscription({...subscription, financialFirstName: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'First name' : 'Prénom' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('financialLastName')}
                            onChange={e => setSubscription({...subscription, financialLastName: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'Last name' : 'Nom' }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('financialEmail')}
                            onChange={e => setSubscription({...subscription, financialEmail: e.target.value})}
                            fullWidth
                            label="Email"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={error && error.missingFields && error.missingFields.includes('financialAddress')}
                            onChange={e => setSubscription({...subscription, financialAddress: e.target.value})}
                            fullWidth
                            label={lang === 'en' ? 'Invoicing address' : 'Adresse de facturation' }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className='divider' />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {lang === 'en' ? 'Application' : 'Adhésion' }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {lang === 'en' ? (
                            <span>The contribution is calculated according to the scale of activity (based on your 2023 turnover):</span>
                        ) : (
                            <span>La cotisation est calculée en fonction du niveau d’activité (Chiffre d’affaires 2023) :</span>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {lang === 'en' ? (
                            <Select
                                labelId="application-select-label"
                                id="application-select"
                                defaultValue=""
                                fullWidth
                                error={error && error.missingFields && error.missingFields.includes('turnover')}
                                onChange={event => setSubscription({...subscription, turnover: event.target.value})}
                            >
                                <MenuItem value="less-than-5-million">Turnover of less than 5 million euros</MenuItem>
                                <MenuItem value="between-5-and-20-million">Turnover between 5 and 20 million euros</MenuItem>
                                <MenuItem value="between-20-and-50-million">Turnover between 20 and 50 million euros</MenuItem>
                                <MenuItem value="between-50-and-150-million">Turnover between 50 and 150 million euros</MenuItem>
                                <MenuItem value="between-150-and-300-million">Turnover between 150 and 300 million euros</MenuItem>
                                <MenuItem value="between-300-and-500-million">Turnover between 300 and 500 million euros</MenuItem>
                                <MenuItem value="between-500-million-and-1-billion">Turnover between 500 million and 1 billion euros</MenuItem>
                                <MenuItem value="between-1-and-2-billion">Turnover between 1 and 2 billion euros</MenuItem>
                                <MenuItem value="between-2-and-5-billion">Turnover between 2 and 5 billion euros</MenuItem>
                                <MenuItem value="more-than-5-billion">Turnover of more than 5 billion euros</MenuItem>
                            </Select>
                        ) : (
                            <Select
                                labelId="application-select-label"
                                id="application-select"
                                defaultValue=""
                                fullWidth
                                error={error && error.missingFields && error.missingFields.includes('turnover')}
                                onChange={event => setSubscription({...subscription, turnover: event.target.value})}
                            >
                                <MenuItem value="less-than-5-million">Chiffre d’affaires inférieur à 5 M€</MenuItem>
                                <MenuItem value="between-5-and-20-million">Chiffre d’affaires compris entre 5 M€ et 20 M€</MenuItem>
                                <MenuItem value="between-20-and-50-million">Chiffre d’affaires compris entre 20 M€ et 50 M€</MenuItem>
                                <MenuItem value="between-50-and-150-million">Chiffre d’affaires compris entre 50 M€ et 150 M€</MenuItem>
                                <MenuItem value="between-150-and-300-million">Chiffre d’affaires compris entre 150 M€ et 300 M€</MenuItem>
                                <MenuItem value="between-300-and-500-million">Chiffre d’affaires compris entre 300 M€ et 500 M€</MenuItem>
                                <MenuItem value="between-500-million-and-1-billion">Chiffre d’affaires compris entre 500 M€ et 1 Md€</MenuItem>
                                <MenuItem value="between-1-and-2-billion">Chiffre d’affaires compris entre 1 Md€ et 2 Md€</MenuItem>
                                <MenuItem value="between-2-and-5-billion">Chiffre d’affaires compris entre 2 Md€ et 5 Md€</MenuItem>
                                <MenuItem value="more-than-5-billion">Chiffre d’affaires supérieur à 5 Md€</MenuItem>
                            </Select>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {lang === 'en' ? (
                            <i>*Turnover corresponds to consolidated worldwide turnover (excluding tax) achieved, where applicable, by all entities operating the brand (all products combined, e.g. clothing, accessories, perfumes, etc.).</i>
                        ) : (
                            <i>*Le Chiffre d’Affaires correspond au Chiffre d’Affaires mondial, consolidé et hors taxes réalisé, le cas échéant, par l’ensemble des entités exploitant la marque (tous produits confondus ex. : vêtements, accessoires, parfums, etc.).</i>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {lang === 'en' ? (
                            <FormControlLabel
                                className={error && error.missingFields && error.missingFields.includes('feesAgreed') ? 'errored' : null }
                                control={<Checkbox onChange={event => setSubscription({...subscription, feesAgreed: event.target.checked })} />}
                                label="Your membership status is confirmed once you are up to date on your memberships’ fees"
                            />
                        ) : (
                            <FormControlLabel
                                className={error && error.missingFields && error.missingFields.includes('feesAgreed') ? 'errored' : null }
                                control={<Checkbox onChange={event => setSubscription({...subscription, feesAgreed: event.target.checked })} />}
                                label="Le statut de membre est acquis lorsque le membre est à jour de ses cotisations"
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <div className='divider' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Signature
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {lang === 'en' ? (
                            <small>Draw your signature in the box below:</small>
                        ) : (
                            <small>Dessinez votre signature dans l'encadré ci-dessous :</small>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {lang === 'en' ? (
                            <div className='rgpd-container'>
                                <span>The Fédération de la Haute Couture et de la Mode undertakes not to use this information for commercial purposes or to pass it on to third parties. You have the right to access, rectify and delete your data. You may also request that the processing of your data be restricted or objected to. To exercise your rights, please contact <a href="mailto:legal@fhcm.paris" target="_blank">legal@fhcm.paris</a>.</span>
                                <span>Syndicat Patronal– N° SIRET 775 671 647 00011</span>
                            </div>
                        ) : (
                            <div className='rgpd-container'>
                                <span>La Fédération de la Haute Couture et de la Mode s’engage à ne pas utiliser ces informations à des fins commerciales ni à les céder à des tiers. Vous disposez d'un droit d'accès, de rectification, d'effacement de vos données. Vous pouvez également demander la limitation du traitement de vos données ou vous opposer à celui-ci. Pour exercer vos droits, veuillez contacter <a href="mailto:legal@fhcm.paris" target="_blank">legal@fhcm.paris</a>.</span>
                                <span>Syndicat Patronal– N° SIRET 775 671 647 00011</span>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='signature-container'>
                            { signatureTooSmall ? (
                                <div className='error-signature'>
                                    <Chip label={lang === 'en' ? 'Signature is too small.' : 'La signature est trop petite' } color="warning" />
                                </div>
                            ) : null }
                            <div className="erase-signature">
                                <Button variant="contained" size="small" onClick={resetPad}>{lang === 'en' ? 'Erase' : 'Effacer' }</Button>
                            </div>
                            <SignatureCanvas canvasProps={{className: 'sigCanvas'}} ref={(ref) => { sigPad = ref }} />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {lang === 'en' ? (
                            <div className='done-in'>
                                <span>Done in</span>
                                <TextField
                                    size="small"
                                    error={error && error.missingFields && error.missingFields.includes('doneIn')}
                                    onChange={e => setSubscription({...subscription, doneIn: e.target.value})}
                                    label={lang === 'en' ? 'Place' : 'Lieu' }
                                />
                                <span>on</span>
                                <TextField
                                    disabled
                                    size="small"
                                    value={dayjs().format('DD MMMM YYYY')}
                                />
                            </div>
                        ) : (
                            <div className='done-in'>
                                <span>Fait à</span>
                                <TextField
                                    size="small"
                                    error={error && error.missingFields && error.missingFields.includes('doneIn')}
                                    onChange={e => setSubscription({...subscription, doneIn: e.target.value})}
                                    label={lang === 'en' ? 'Place' : 'Lieu' }
                                />
                                <span>le</span>
                                <TextField
                                    disabled
                                    size="small"
                                    value={dayjs().format('DD MMMM YYYY')}
                                />
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {lang === 'en' ? (
                            <FormControlLabel
                                className={error && error.missingFields && error.missingFields.includes('agreed') ? 'errored' : null }
                                control={<Checkbox onChange={event => setSubscription({...subscription, agreed: event.target.checked })} />}
                                label="By ticking this box, I certify on my honour that the above information is correct."
                            />
                        ) : (
                            <FormControlLabel
                                className={error && error.missingFields && error.missingFields.includes('agreed') ? 'errored' : null }
                                control={<Checkbox onChange={event => setSubscription({...subscription, agreed: event.target.checked })} />}
                                label="En cochant cette case, je certifie sur l’honneur l’exactitude des informations ci-dessus."
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        { error ? (
                            <div className='error-message'>
                                {lang === 'en' ? (
                                    <span>Please fill all information required.</span>
                                ) : (
                                    <span>Merci de remplir les champs demandés.</span>
                                )}
                            </div>
                        ) : null }
                    </Grid>
                    <Grid item xs={12}>
                        <button disabled={buttonLoading} onClick={handleSubmit} className='main-button blue-button launch'>{buttonLoading ? <CircularProgress size={20} /> : (lang === 'en' ? 'Submit' : 'Envoyer')}</button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default SubscriptionForm;